import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The wind howls in the backyard,`}<br parentName="p"></br>{`
`}{`Oozes through the fissures in the old moldy fence.`}<br parentName="p"></br>{`
`}{`It rushes to the left, it hurtles back, it rises up and plunges down,`}<br parentName="p"></br>{`
`}{`At the fool speed it crashes into the dampened ground.`}<br parentName="p"></br>{`
`}{`It is looking for its place.`}</p>
    <p>{`The wind drowns out the wailing cries of shabby foxes.`}<br parentName="p"></br>{`
`}{`Nose deep into the dead parched leaves,`}<br parentName="p"></br>{`
`}{`They dodder, they stroll and suddenly they dart in fear`}<br parentName="p"></br>{`
`}{`As if escaping from a monstrous hound.`}<br parentName="p"></br>{`
`}{`Thousands of trails tangle on the wounded ground.`}<br parentName="p"></br>{`
`}{`They are looking for their place.`}</p>
    <p>{`The wind never stops, it hastens further`}<br parentName="p"></br>{`
`}{`Thousands of miles away, to what seems like a different universe.`}<br parentName="p"></br>{`
`}{`Where somber waters of the restive ocean`}<br parentName="p"></br>{`
`}{`Hare to pierce riveted cliffs.`}<br parentName="p"></br>{`
`}{`They tear to the shore, they rise in assail, they slump onto the whetter rocks, they pleat.`}<br parentName="p"></br>{`
`}{`As white and tarnished gore the waves retreat.`}<br parentName="p"></br>{`
`}{`They are looking for their place.`}</p>
    <p>{`The wind keeps playfully tugging`}<br parentName="p"></br>{`
`}{`my collar, my sleeves and the hem of my coat`}<br parentName="p"></br>{`
`}{`While I leave behind rows of gaudy buildings`}<br parentName="p"></br>{`
`}{`In the suburbs of the prodigious city.`}<br parentName="p"></br>{`
`}{`I don’t scuttle or lurch or reel.`}<br parentName="p"></br>{`
`}{`And yet my thoughts cannot escape the all-consuming chaos.`}<br parentName="p"></br>{`
`}{`Thousands of questions left without answers.`}<br parentName="p"></br>{`
`}{`I am looking for my place.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      